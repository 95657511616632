/* ----------------------------------------------------------------------------------------------------------------*/
/* ---------->>> global settings needed for thickbox <<<-----------------------------------------------------------*/
/* ----------------------------------------------------------------------------------------------------------------*/
* {}

/* ----------------------------------------------------------------------------------------------------------------*/
/* ---------->>> thickbox specific link and font settings <<<------------------------------------------------------*/
/* ----------------------------------------------------------------------------------------------------------------*/
#TB_window {
	font: 12px Arial, Helvetica, sans-serif;
	color: #333333;
	padding: 0; margin: 0;
}

#TB_secondLine {
	font: 10px Arial, Helvetica, sans-serif;
	color:#fff;
	padding: 0; margin: 0;
}

#TB_title a:link, #TB_title a:visited, #TB_title a:active, #TB_title a:focus {color: #fff;padding:0 3px;margin:0;text-decoration: none}
#TB_title a:hover {background-color: #fff;color: #693;padding:0 3px;margin: 0;text-decoration: none}


/* ----------------------------------------------------------------------------------------------------------------*/
/* ---------->>> thickbox settings <<<-----------------------------------------------------------------------------*/
/* ----------------------------------------------------------------------------------------------------------------*/
#TB_overlay {
	position: fixed;
	z-index:100000;
	top: 0px;
	left: 0px;
	height:100%;
	width:100%;
	padding: 0; margin: 0;
}

.TB_overlayMacFFBGHack {background: url(../img/macFFBgHack.png) repeat;padding: 0; margin: 0;}
.TB_overlayBG {
	background-color:#000;
	filter:alpha(opacity=80);
	-moz-opacity: 0.8;
	opacity: 0.8;
	padding: 0; margin: 0;
}

* html #TB_overlay { /* ie6 hack */
     position: absolute;
     height: expression(document.body.scrollHeight > document.body.offsetHeight ? document.body.scrollHeight : document.body.offsetHeight + 'px');
     padding: 0; margin: 0;
}

#TB_window {
	position: fixed;
	background:#fff;
	z-index: 102000;
	color:#333;
	display:none;
	border: 1px solid #ccc;
	text-align:left;
	top:50%;
	left:50%;
	padding: 0; margin: 0;
	
}
/* Border Shadow added by Rob S., radius added by YMS */
#TB_window { 
	-moz-box-shadow: 0 0 7px #000; -webkit-box-shadow: 0 0 7px #000; box-shadow: 0 0 7px #000; padding: 0; margin: 0;  
	-webkit-border-radius: 6px;
-moz-border-radius: 6px;
border-radius: 6px;
}

* html #TB_window { /* ie6 hack */
	position: absolute;
    padding: 0; margin: 0;
    margin-top: expression(0 - parseInt(this.offsetHeight / 2) + (TBWindowMargin = document.documentElement && document.documentElement.scrollTop || document.body.scrollTop) + 'px');
}

#TB_window img#TB_Image {
	display:block;
	padding: 0;
	margin: 15px 0 0 15px;
	border-right: 1px solid #ccc;
	border-bottom: 1px solid #ccc;
	border-top: 1px solid #666;
	border-left: 1px solid #666;
}

#TB_caption{
	height:25px;
	margin: 0;
	padding:7px 30px 10px 25px;
	float:left;
}

#TB_closeWindow{
	height:25px;
	margin: 0;
	padding:11px 25px 10px 0;
	float:right;
}

#TB_closeAjaxWindow{
    margin: 0;
	padding:7px 10px 5px 0;
	margin-bottom:1px;
	text-align:right;
	float:right;
}

#TB_ajaxWindowTitle{
	float:left;
	margin: 0;
	padding:7px 0 5px 10px;
	margin-bottom:1px;
}

#TB_title{
	background:transparent;
	border-bottom:1px solid #ccc;
	height:27px;
	color:#fff;
	font-weight:bold;
	padding: 0; margin: 0;
}

#TB_ajaxContent{
	clear:both;
	padding:15px;
	margin: 0;
	overflow:auto;
	text-align:left;
	line-height:1.4em;
	background:transparent;
}

#TB_ajaxContent.TB_modal{
	padding:15px;
	margin: 0;
}

/* //let bootstrap decide
#TB_ajaxContent p{
	margin:0; 
	padding:5px 0px 5px 0px;
	line-height:1;
}
*/
#TB_load{
	position: fixed;
	display:none;
	height:13px;
	width:208px;
	z-index:103000;
	top: 50%;
	left: 50%;
	padding: 0;
	margin: -6px 0 0 -104px; /* -height/2 0 0 -width/2 */
}

* html #TB_load { /* ie6 hack */
    position: absolute;
    padding: 0; margin: 0;
    margin-top: expression(0 - parseInt(this.offsetHeight / 2) + (TBWindowMargin = document.documentElement && document.documentElement.scrollTop || document.body.scrollTop) + 'px');
}

#TB_HideSelect{
	z-index:99000;
	position:fixed;
	top: 0;
	left: 0;
	background-color:#fff;
	border:none;
	filter:alpha(opacity=0);
	-moz-opacity: 0;
	opacity: 0;
	height:100%;
	width:100%;
	padding: 0; margin: 0;
}

* html #TB_HideSelect { /* ie6 hack */
     position: absolute;
     height: expression(document.body.scrollHeight > document.body.offsetHeight ? document.body.scrollHeight : document.body.offsetHeight + 'px');
     padding: 0; margin: 0;
}

#TB_iframeContent{
	clear:both;
	border:none;
	padding: 0; margin: 0;
	margin-bottom:-1px;
	margin-top:1px;
	_margin-bottom:1px;
}

/* custom classes for thickbox */
#TB_window.transparent, #TB_window.transparent #TB_title, #TB_window.transparent #TB_ajaxContent{
	background:transparent;
	border:none;
	padding: 0; margin: 0;
	box-shadow:none;
}
#TB_window.transparent > iframe{
	-webkit-box-shadow:0 0 20px #000;
	   -moz-box-shadow:0 0 20px #000;
	        box-shadow:0 0 20px #000;
}
#TB_window.nopadding #TB_ajaxContent{
	padding:0;
	margin:0;
}

#TB_window.notitle #TB_title{
    border-bottom:none;
}
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Thickbox Overrides  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

#TB_ajaxWindowTitle{
	text-transform: uppercase;
	font-weight: 300;
	font-size: 12px;
	line-height: 25px;
	margin-left: 2px;
}
#TB_ajaxContent{overflow-x:hidden}

/*widget ad*/
#TB_ajaxContent.widgetAd, #TB_window.widgetAd #TB_ajaxContent.TB_modal{padding:0px;overflow:hidden}
#TB_window.widgetAd{
	width:inherit;height:auto;
	-webkit-border-radius: 0px;
	-moz-border-radius: 0px;
	border-radius: 0px;
}
#widget_ad_closer{position:absolute;z-index:9999999;display:none}
#widget_ad_closer a{color:#fff;display:block;width:16px;height:16px;}

#TB_title a:link, #TB_title a:visited, #TB_title a:active, #TB_title a:focus{
	color:#999;
	font-size:20px;
	font-weight:bold;
}
#TB_title{
	background:transparent;border:0;border-bottom: 1px solid #EEE;
	font-size:16px;color:#999;font-weight:normal;
	padding-bottom: 12px;
}
#TB_title a:hover{background:transparent; color:#454545;}
