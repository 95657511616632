/**
 * The magnificent clearfix
 */
.clearfix:before, .clearfix:after {content:"\0020"; display:block; height:0; overflow:hidden;}
.clearfix:after {clear:both;}
.clearfix {zoom:1;}

body{text-align:center}
 
/*archives*/
h3{margin-bottom:35px}
ul#archive_issues{list-style:none;padding:0px;margin:0px;margin-top:20px;}
ul#archive_issues li{float:left;clear:none;padding:0px;margin:0 20px 0 0;height:240px;width:120px;text-align:center;word-wrap: break-word}
ul#archive_issues li a{display:block;max-height:180px;overflow:hidden}
.archive_title{clear:left;float:left;color:#454545;font-size:11px;width:90px;padding:5px;line-height:13px}
.archive_date{color:#555;font-size:9px;padding:3px 4px;line-height:11px;background-color:#eee;-webkit-border-radius:4px 4px 0 0;z-index:-1;width:75px}
.archive_date{-webkit-transition:background-color 0.2s linear;-moz-transition:background-color 0.2s linear;-ms-transition:background-color 0.2s linear;-o-transition:background-color 0.2s linear;transition:background-color 0.2s linear;}
.archive_date.hover{background-color:#ddd;}
.archive_search_form{padding:5px;background:#f5f5f5;border-bottom:1px solid #eee;margin-bottom:20px}
.archive_search_form #archive_search{width:150px;margin-bottom:0}

/*modals*/
#modal-title, .modal-title{font-size: 115%;font-weight: bold;margin:10px 0;color:#333}
#modal-title span, .modal-title span{color:#666}
.modal-title span span, .modal-title div span{color:#999}
.modal-title div{font-size:85%;margin-top:6px;margin-left:6px;color:#666;}
#modal-title span{color:#666}
.issue-img-wrapper{
	float:left;clear:none;
	border:1px solid #ccc;
	-moz-box-shadow:3px 3px 5px #ccc;
	-webkit-box-shadow:3px 3px 5px #ccc;
	box-shadow:3px 3px 5px #ccc;
}

/* progress */
#progress, .progress{
	padding:4px;
	padding-left:28px;
	background: #f1f1f1 url(../img/ajax-loader.gif) no-repeat 4px 4px;
	
}
#progress{
	position:absolute;
	top:4px;
	left:4px;
	z-index:10000;
	display:none;
	border:1px solid #ddd;
}
.progress{background-color:transparent}

/* thumbs/icons */
.icon {float:left}
.icon a{
	display: block;
	border:1px solid #ccc;
	-moz-box-shadow:3px 3px 5px #ccc;
	-webkit-box-shadow:3px 3px 5px #ccc;
	box-shadow:3px 3px 5px #ccc; 
	overflow: hidden;
}
.icon a:hover{border-color:#3698b5}

/*--- TOOLTIP -----*/
#tooltip{
	position:absolute;
	background:#212121;
	padding:2px 5px;
	color:#fff;
	display:none;
	z-index:9999999;
	font-weight:bold;
}	
#tooltip h3, #tooltip div { margin: 0;font-size:11px; color:#fff;font-weight:normal;padding:0;line-height:11px;}

.clr{clear:both;}

/*----- CONTENT -----*/
#content-title, #content-subtitle, .content-title, .content-subtitle{
	font-weight:bold;
	font-size:30px;
	margin-bottom:16px;
}
#content-subtitle, .content-subtitle{font-size:20px;color:#454545}

#content-title span, #content-subtitle span{color:#999;font-family:'Arial Unicode MS'}
#content-title span span, #content-subtitle span span{color:#999;font-weight:normal}

.content-heading{font-weight:bold;font-size:16px;color:#454545;margin-bottom:4px;margin-top:16px;}
.content-heading span{color:#666;font-weight:normal}

/* ----------- CLIPPINGS ---------*/
.clipping_title{font-weight:bold;color:#444;font-size:14px}
.clipping_caption{font-size:11px;color:#666;padding-top:8px;padding-bottom:8px;width:140px}
.clipping_links{border-top:2px solid #eee;padding-top:8px;}
.clipping_links img{margin-left:8px;vertical-align:middle;width:16px;}

/* clipping widget */
#table_clipping_widget{width:100%}
#table_clipping_widget tr.off td{color:#999}
#table_clipping_widget tr.on td{color:#333}
#table_clipping_widget tr.off img{opacity: 0.5;filter: alpha(opacity = 50);}
#table_clipping_widget tr.on img{opacity: 1;filter: alpha(opacity = 100);}
#table_clipping_widget td{font-size:12px;padding:3px;border-top:2px solid #eee}
#table_clipping_widget .clipping_widget_thumb img{border:1px dashed #333;width:60px;}

/* ---- FORMS ----- */
.form-note, .note {
    background:#F5F5F5;
    color: #777777;
    margin: 0.3em;
    padding: 3px;
}
.form-large .inline, .form-inline {
float: left;
clear: none;
padding-right: 5px;
}
.error_message{color:#B94A48;font-size:11px;background:#F2DEDE;padding:3px;margin-top:0px;margin-bottom:6px;}

/* --- SUBSCRIPTION --- */
table.tableSubscriptionOptions{font-size:12px;}
table.tableSubscriptionOptions th{background:#eee;font-weight:bold;padding:4px;text-align:left}
table.tableSubscriptionOptions td{border-bottom:1px solid #eee;font-weight:normal;padding:4px;text-align:left;}
table.tableSubscriptionOptions td .mini{font-size:9.5px;color:#666;padding:2px 4px;background:#f5f5f5}
.price{font-weight:bold;color:green}

.subscriptionOptionTitle{font-size:120%;font-weight:bold;margin-bottom:10px;}

.subscriptionOptionsOR{text-align:center;clear:both;font-weight:bold;font-style:italic;}

.subscriptionItem {border:1px solid #ccc;}
.subscriptionItem td.title{padding:3px;font-weight:bold;background:#ccc; border-radius:3px; -moz-border-radius:3px; -webkit-border-radius:3px;}
.checkout-item{clear:both;padding:12px;width:256px;overflow:hidden}

/*-- BOOTSTRAP overrides --*/
input[type="text"], select{width:98%;}
label{font-weight:bold;color:#454545}
td label{text-align:right}
.btn-social{display:block;text-align:left;}
/* hide close x for flash message */
div#flash_message a.close{display:none}

/*--- HELP ---*/
div#flipbook_help h3{margin-top:40px;margin-bottom:4px;}
div#flipbook_help img.screen{
	margin:8px 0;
	border:1px solid #ccc;
	-moz-box-shadow:3px 3px 5px #ccc;
	-webkit-box-shadow:3px 3px 5px #ccc;
	box-shadow:3px 3px 5px #ccc;
}

/* ------------------ TAB CONTENT ----------------- */
/* mini tabs elements */
#tabnav, .tabnav {
	height: 34px;
	margin: 0;
	margin-top: 12px;
	padding-left: 20px;
	background: transparent url(../img/tab_bottom.gif) repeat-x bottom;
}
#tabnav li,.tabnav li {
	margin: 0; 
	padding: 0;
	display: inline;
	list-style-type: none;
}
	
#tabnav a:link, #tabnav a:visited, .tabnav a:link, .tabnav a:visited {
	float: left;
	
	font-size: 11px;
	line-height: 14px;
	font-weight: bold;
	padding: 4px 10px 4px 10px;
	margin-right: 4px;
	margin-top:10px;
	
	background: #eee;
	text-decoration: none;
	color: #333;
}

#tabnav a:link.active, #tabnav a:visited.active, .tabnav a:link.active, .tabnav a:visited.active {
	border:1px solid #ccc;
	border-right-color: #ddd;
	border-bottom: 1px solid #fff;
	background: url(../img/tab_bg.gif) #fff repeat-x;
	color: #666;
	text-decoration: none;
	
}

#tabnav a:hover, .tabnav a:hover {
	background-color:#900;
	text-decoration: none;
	color:#fff;
}

#tabnav a.inactive, #tabname a.inactive:hover, .tabnav a.inactive{color:#aaa;background:#eee}

#tabnav a span, #tabnav a:hover.active span, .tabnav a span, .tabnav a:hover.active span {font-weight:normal;color:#888}
#tabnav a:hover span, .tabnav a:hover span {color:#ddd}

#not_found{width:600px;margin-top:200px;padding-left:40px;background:transparent url(../img/warning.png) no-repeat 0px 14px}
#not_found_content{border:1px solid #aaa;padding:16px;text-align:left;box-shadow: 5px 5px 5px #ccc;border-radius: 8px;-moz-border-radius:8px;-webkit-border-radius:8px;}

#title_background_image_stretch{z-index:0;position:absolute;height:100%;width:100%;top:0;left:0;margin:0}
#title_background_image_stretch img{width:100%;height:100%;border:0}

/* Button Caret */
.caret.caret-upwards {
  border-top: 0;
  border-bottom: 4px solid #000000;
  content: "\2191";
}
#TB_window.tb-window-absolute {
    position: absolute;
}
